<template>
  <div class="backShadow"  v-if="show">
    <div class="modal-container">
      <Icon v-bind:type="type"/>
      <div class="msg textAverage">{{msg}}</div>
        <!-- Area dos botões -->
      <div v-if="type!='Loader'" class="buttonArea">
        <button v-if="type!='Fault' && type!='Confirmation'" @click="cancel()" class="btn btnAverageSize btnTypeCancel">Cancelar</button>
        <button @click="ok()" class="btn btnAverageSize btnTypeSave">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue"
export default {
  components:{Icon},
  props:{
    show: Boolean,
    msg: String,
    msgBoolean: String,
    type: String,
    onOk:{
      type: Function,
      default: null
    },
    onCancel:{
      type: Function,
      default: null
    }
  },
  methods:{
    cancel(){
      if(this.onCancel){
        this.onCancel();
      }
    },
    ok(){
      if(this.onOk){
        this.onOk();
      }
    }
  },
}
</script>

<style scoped>
  .btnAverageSize{
    margin-left:10px; 
    margin-right:10px
  }
  .backShadow {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-container {
    width: fit-content;
    height: fit-content;
    padding:30px;
    background-color: #292d32;;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    font-family: Helvetica, Arial, sans-serif;
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  .row{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .msg{
    text-align: center;
    margin:20px 0px 10px 0px ;
    color: aliceblue;
  }
  .buttonArea{
    margin:20px 0px 0px 0px ;
    display:flex;
    flex-direction: row;
  }
  
</style>
