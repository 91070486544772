import * as Realm from "realm-web";
import { ObjectId } from "bson";
import { UserPermissions } from "../Model/Device/Device";

export default class BackEnd{
    static app = new Realm.App("firebeeconnect-hvdfz");
    // static app = new Realm.App("firebeeconnectdevelopment-jqfqn");  
    static listOfCollection=[];
    static mongo:globalThis.Realm.Services.MongoDB=null;
    static user:Realm.User=null;
    static updateUser = null;

    static async login(email:string, password:string){
        BackEnd.user = await BackEnd.app.logIn( Realm.Credentials.emailPassword(email,password));
        BackEnd.mongo = BackEnd.user.mongoClient("mongodb-atlas");
        if(this.updateUser) {
            this.updateUser();
        }

        // Verifica se o usuário está bloqueado
        await BackEnd.user.refreshCustomData();
        if(this.user.customData.deletedAt != null) {
            this.user.logOut();
            throw("Usuário bloqueado, favor entrar em contato com o suporte.");
        }

        // Atribui um realmId ao usuário da coleção User no seu primeiro login
        if(BackEnd.user.isLoggedIn){
            let user = await this.function().readUser(email);
            if(user.realmId == null) {
                await this.function().updateUserRealmId(user._id, this.user.id);
                BackEnd.user.refreshCustomData();
            }
        }
    }

    static addListenerUser(listener: any){
        this.updateUser = listener;
    }

    static async getUser(realmId:string){
        let users = BackEnd.user.app.allUsers;
        let user = users[realmId];
        console.log(user.customData.name);
        if(user) {
            return user;
        } else {
            throw("Usuário não encontrado!!!");
        }
    }

    static getCurrentUser(){
        return this.user;
    }

    static getCurrentUserId(){
        return this.user.id;
    }

    static getCurrentUserAccess(){
        return this.user.customData.accessLevel.$numberInt;
    }

    static getCurrentUserServices(){
        return this.user.customData.permissions.services;
    }

    static async registerUser(email:string){
        return await BackEnd.user.app.emailPasswordAuth.registerUser(email,this.generatePassword() + "");
    }

    static async deleteUser(user:any){
        return await BackEnd.user.app.removeUser(user);
    }

    static async sendResetPasswordEmail(email:string, newUser:boolean){
        console.log("Entrando na rotina de envio de e-mail");
        await BackEnd.app.emailPasswordAuth.callResetPasswordFunction(email, this.generatePassword() + "", newUser);
    }

    static async resetPassword(token:string, tokenId:string, newPassword:string){
        console.log("Reset Password: " + newPassword + " token: " + token + " tokenId: " + tokenId);
        await BackEnd.app.emailPasswordAuth.resetPassword(token, tokenId, newPassword);
    }

    static generatePassword(){
        return Math.floor(Math.random() * 900000) + 100000;
    }

    static isLogged(){
        if(BackEnd.user!=null){
            return BackEnd.user.isLoggedIn;
        }
        return false;
    }

    static function(){
        return BackEnd.user.functions;
    }

    static async getCollection(name:string){
        var collectionObj=BackEnd.listOfCollection.find(element=> element.name==name);
        if(collectionObj!=null){
            console.log("Return collection");
            return collectionObj.collection;
        }
        collectionObj={
            name:name,
            collection: BackEnd.mongo.db("FirebeeConnect").collection(name)
        }
        BackEnd.listOfCollection.push(collectionObj);
        console.log("Return new collection");
        return collectionObj.collection;
    }

    static sleep(ms: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    static compareUser(user: UserPermissions, userList: Array<UserPermissions>){
        for(let i=0; i < userList.length; i++) {
            if(userList[i].email == user.email) {
                return true;
            }
        }
        return false;
    };

    static async syncToCollection(collectionName:string, filter:Object, litsOfDocuments:any){
        var collection=await BackEnd.getCollection(collectionName)
        for await (const change of collection.watch(filter)){
            const { operationType } = change;
            switch (operationType) {
                case "insert": {
                    const { fullDocument } = change;
                    var document=litsOfDocuments.find(document=>document._id.equals(fullDocument._id));
                    if(document==null){
                        console.log("Inserindo novo Documento: ", fullDocument);
                        litsOfDocuments.push(fullDocument);
                    }else{
                        console.log("Esse documento já existe.",fullDocument);
                    }
                    break;
                }
                case "update":
                case "replace": {
                    const { fullDocument } = change;
                    var index=litsOfDocuments.findIndex(document=>document._id.equals(fullDocument._id));
                    if(index>=0){
                        Object.assign(litsOfDocuments[index], fullDocument);
                    }else{
                        console.log("Documento não encontrado, portanto sua atualização foi ignorada.",fullDocument);
                    }
                    break;
                }
                case "delete": {
                    const { documentKey } = change;
                    var index=litsOfDocuments.findIndex(element=>element._id.equals(documentKey._id));
                    if(index>=0){
                        console.log("Deletando Documento: ",documentKey);
                        litsOfDocuments.splice(index,1);
                    }else{
                        console.log("Documento não encontrado, portanto não será deletado.",documentKey)
                    }
                    break;
                }
            }
        }
    }
}

export {BackEnd,ObjectId};