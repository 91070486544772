<template>
  <div v-if="showMenu" class="ResponsiveMenu">
    <div class="ResponsiveMenuItem">
      <img src="~@/assets/icons/FirebeeIcon.svg" class="RepsonsiveIconFirebee"/>
      <img src="~@/assets/icons/FirebeeLogo.svg" class="RepsonsiveLogoFirebee hide"/>
    </div>
    <router-link class="ResponsiveMenuItem ResponsiveBtnMenu" active-class="ResponsiveBtnActive" exact to="/ListOfDevices">
      <img class="ResponsiveIconBtnMenu" src="~@/assets/icons/menu/home.svg" />
      <label class="textAverage hide">Início</label>
    </router-link>
    <router-link v-if="showConfig" class="ResponsiveMenuItem ResponsiveBtnMenu" active-class="ResponsiveBtnActive" to="/Setting">
      <img class="ResponsiveIconBtnMenu" src="~@/assets/icons/menu/setting.svg"/>
      <label class="textAverage hide">Configurar</label>
    </router-link>
    <div class="ResponsiveMenuItem ResponsiveBtnMenu" v-on:click="logOut()">
      <img class="ResponsiveIconBtnMenu" src="~@/assets/icons/menu/logout.svg"/>
      <label class="textAverage hide">Logout</label>
    </div>
    <PopUp msg="Relmente deseja realizar Logout?" :show="flagLogOut" type="Question" :onOk="popUpOk" :onCancel="popUpCancel"/>
  </div>
</template>

<script>
import PopUp from "@/views/PopUp/PopUp.vue";
import router from "@/router/index";
import BackEnd from "@/sys/BackEnd/BanckEnd";
import {AccessLevel} from '@/sys/Model/Permissions/AccessLevel';

export default {
  components: { PopUp },
  name: "Menu",
  data() {
    return {
      flagLogOut: false,
      showConfig: true,
      showMenu: true
    };
  },
  created() {
    this.showConfigFunction();
    BackEnd.addListenerUser(this.showConfigFunction);
  },
  methods: {
    logOut: async function () {
      console.log("LogOut requer");
      this.flagLogOut = true;
    },
    popUpOk: function () {
      console.log("LogOut OK");
      this.flagLogOut = false;
      this.showMenu = false;
      router.push({ path: "/Login" });
    },
    popUpCancel: function () {
      console.log("LogOut Cancel");
      this.flagLogOut = false;
    },
    showConfigFunction(){
      this.showMenu = true;
      let accessLevel = BackEnd.getCurrentUserAccess();
      if (accessLevel == AccessLevel.DIRETOR || accessLevel == AccessLevel.PRODUCAO || accessLevel == AccessLevel.VENDAS) {
        this.showConfig = true;
      } else {
        this.showConfig = false;
      }
    }
  }
};
</script>

<style scoped>
.router-link-active {
  background-color: #4375ef;
}
.ResponsiveIconBtnMenu {
  width: 40px;
  margin: 0 10px 0 0
}
@media (orientation: portrait) {
  .ResponsiveIconBtnMenu {
    margin: 0 0 20px 0;
  }
}
@media (max-width: 1450px) {
  .ResponsiveIconBtnMenu {
    width: 30px
  }
}
@media (max-width: 825px) {
  .ResponsiveIconBtnMenu {
    width: 28px
  }
}
@media (max-width: 600px) {
  .ResponsiveIconBtnMenu {
    margin: 0;
  }
}
</style>