<template>
  <transition>
    <div>
      <svg v-if="type=='Fault'" class="IconArea AnimationFault" viewBox="0 0 52 52">
        <circle class="IconCircle ColorFault" cx="26" cy="26" r="25" fill="none"/>
        <path class="IconDraw " fill="none" d="M15 35 l20-20 M15 15 l20 20"/>
      </svg>
      <svg v-if="type=='Alert'" class="IconArea AnimationAlert" viewBox="0 0 52 52">
        <circle class="IconCircle ColorAlert" cx="26" cy="26" r="25" fill="none"/>
        <path class="IconDraw " fill="none"  d="M26 30 l0-20 M26 33 l0 6"/>
      </svg>
      <svg v-if="type=='Confirmation'" class="IconArea AnimationConfirmation" viewBox="0 0 52 52">
        <circle class="IconCircle ColorConfirmation" cx="26" cy="26" r="25" fill="none"/>
        <path class="IconDraw " fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
      <svg v-if="type=='Question'" class="IconArea AnimationQuestion" viewBox="0 0 52 52">
        <circle class="IconCircle ColorQuestion" cx="26" cy="26" r="25" fill="none"/>
        <path class="IconDraw " fill="none" d="M17.334113 17.9120995c2.893932-2.296619 5.702122-4.038991 9.510761-3.890586 4.689382.825192 6.494846 3.959337 6.172229 7.01958-.263597 2.5004-1.900217 5.100645-2.713744 5.840367-1.015046.922958-3.630229 4.056541-3.836624 5.161036l-.104522 4.512058M26.164698 39.1847525l-.09794 5.933063"/>
      </svg>
      <div v-if="type=='Loader'" class="Loader"/>
    </div>
  </transition>
</template>

<script>
export default {
    props:{
        type:{
          type:String,
          default:"none"
        }
    }
}
</script>

<style scoped>
  .IconCircle{
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  .IconArea {
    width: 61px;
    height: 61px;
    border-radius: 50px;
    display: block;
    stroke-width: 6;
    stroke: #fff;
    stroke-miterlimit: 10;
  }
  .IconDraw {
    transform-origin: 50% 50%;
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  .ColorFault{
    stroke:  #ff3c3c;
  }
  .AnimationFault{
    animation: keyFrameFault .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  .ColorAlert{
    stroke: #ffc22d;
  }
  .ColorQuestion{
    stroke: #4375EF;
  }
  .AnimationAlert{
    animation: keyFrameAlert .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  .ColorConfirmation{
    stroke:  #29CB72;
  }
  .AnimationConfirmation{
    animation: keyFrameConfirmation .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  .AnimationQuestion{
    animation: keyFrameQuestion .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  .Loader{
    border: 8px solid #1a1a22;
    border-left-color: #4375EF;
    height: 61px;
    width: 61px;
    border-radius:50%;
    animation: Loader 1s linear infinite;
  }
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes keyFrameFault {
    100% {
      box-shadow: inset 0px 0px 0px 30px #ff3c3c;
    }
  }
  @keyframes keyFrameAlert {
    100% {
      box-shadow: inset 0px 0px 0px 30px #ffc22d;
    }
  }
  @keyframes keyFrameConfirmation {
    100% {
      box-shadow: inset 0px 0px 0px 30px #29CB72;
    }
  }
  @keyframes keyFrameQuestion {
    100% {
      box-shadow: inset 0px 0px 0px 30px #4375EF;
    }
  }
  @keyframes Loader{
    to {
      transform: rotate(360deg);
    }
  }
</style>