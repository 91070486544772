import Vue from 'vue'
import VueRouter from 'vue-router'
import BackEnd from '@/sys/BackEnd/BanckEnd'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      redirect: '/ListOfDevices',
      component: () => import('../views/content/Home/Home.vue'),
      children:[
        { 
          path:'/ListOfDevices',
          name: 'ListOfDevices',
          component: () => import('../views/content/Home/ListOfDevices/ListOfDevices.vue')
        },
        { 
          path:'/DeviceAccess/',
          name: 'DeviceAccess', 
          redirect: '/DeviceAccess/AcessType/',
          props: true,
          component: () => import('../views/content/Home/DeviceAccess/DeviceAccess.vue'),
          children:[
            {
              path:'/DeviceAccess/AcessType/',
              name: 'AcessType', 
              props: true,
              component: () => import('../views/content/Home/DeviceAccess/AcessType/AcessType.vue'),
            },
            {  
              path:'/DeviceAccess/DashBoard',
              name: 'DashBoard',
              props: true,
              component: () => import('../views/content/Home/DeviceAccess/DashBoard/DashBoard.vue')
            },
            {  
              path:'/DeviceAccess/RDP',
              name: 'RDP',
              props: true,
              component: () => import('../views/content/Home/DeviceAccess/RDP/RDP.vue')
            },
            {  
              path:'/DeviceAccess/SSH',
              name: 'SSH',
              props: true,
              component: () => import('../views/content/Home/DeviceAccess/SSH/SSH.vue')
            },
            {  
              path:'/DeviceAccess/FTP',
              name: 'FTP',
              props: true,
              component: () => import('../views/content/Home/DeviceAccess/FTP/FTP.vue')
            }
          ]
        },
      ]
    },
    {
      path: '/Setting',
      name: 'Setting',
      redirect: '/Setting/ChooseSetting',
      component: () => import('@/views/content/Setting/Setting.vue'),
      children:[
        {
          path:'/Setting/Users/RegisterUser',
          name:'RegisterUser',
          props: true,
          component: () => import('@/views/content/Setting/Users/UserEdit.vue')
        },
        {
          path:'/Setting/ChooseSetting',
          name:'ChooseSetting',
          component: () => import('@/views/content/Setting/ChooseSetting/ChooseSetting.vue')
        },
        {
          path:'/Setting/Users',
          name:'Users',
          component: () => import('@/views/content/Setting/Users/Users.vue'),
        },
        {
          path:'/Setting/Clients',
          name:'Clients',
          component: () => import('@/views/content/Setting/Clients/Clients.vue')
        },
        {
          path:'/Setting/Unitys',
          name:'Unitys',
          component: () => import('@/views/content/Setting/Unities/Unities.vue')
        },
        {
          path:'/Setting/Licenses',
          name:'Licenses',
          component: () => import('@/views/content/Setting/Licenses/Licenses.vue')
        },
        {
          path:'/Setting/Devices',
          name:'Devices',
          component: () => import('@/views/content/Setting/Devices/Devices.vue')
        }
      ]
    },
    {
      path: '/Login',
      name: 'Login',
      component: () => import('../views/content/Login/Login.vue')
    },
    {
      path: '/ForgotPassword',
      name: 'ForgotPassword',
      component: () => import('@/views/content/ForgotPassword/ForgotPassword.vue')
    },
    {
      path: '/CreatePassword',
      name: 'CreatePassword',
      props: route=>({token:route.query.token, tokenId:route.query.tokenId, newUser:route.query.newUser}),
      component: () => import('@/views/content/CreatePassword/CreatePassword.vue')
    }
  ]
})

router.beforeEach((to,from,next)=>{
  if(BackEnd.isLogged()){
    console.log("Nexto to: "+to.fullPath);
    next();
  }else{
    if(to.name!='Login'&& to.name!='ForgotPassword' && to.name!='CreatePassword'){
      //next({name:'Login', params:{path:to}});
      next({name:'Login'});
    }else{
      next();
    }
  }
})

export default router
