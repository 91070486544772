<template>
  <div class="ResponsiveBody" id="ResponsiveBody">
    <Menu v-if="BackEnd.isLogged()"/>
    <router-view />
  </div>
</template>

<script>
  import Menu from './views/Menu/Menu.vue'
  import BackEnd from '@/sys/BackEnd/BanckEnd'

  export default {
    components:{Menu},
    data() {
      return {
        BackEnd:BackEnd
      }
    }
  }
</script>

<style>
input, input:before, input:after {
  user-select: initial;
}
/* Input */
.eventClickArea{
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* Loader center position*/
.loaderPosition{
  position: absolute;
  width: 65px;
  height: 65px;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
}
.Loader{
  border: 8px solid #1a1a22;
  border-left-color: #4375EF;
  height: 61px;
  width: 61px;
  border-radius:50%;
  animation: Loader 1s linear infinite;
}
@keyframes Loader{
  to {
    transform: rotate(360deg);
  }
}

/* Para resolução HD (1280x720) */
@media (max-width: 1380px) {
  .loaderPosition{
    width: 40px;
    height: 40px;
  }
  .Loader{
    border: 5px solid #1a1a22;
    height: 38px;
    width: 38px;
  }
}
</style>
